import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedComponent } from '../../shared/shared.component';
import { Account } from '../model/account.model';
import { AuthService } from '../_services/auth.service';
import { AccountService } from '../_services/domainservice/account.service';

@Component({
  selector: 'app-sendotp',
  templateUrl: './sendotp.component.html',
  styleUrls: ['./sendotp.component.css']
})
export class SendotpComponent extends SharedComponent implements OnInit {
  Email: string;
  otp: string;
  message: string;
  account: Account = new Account();
  constructor(private accountService: AccountService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router) {
    super();

    //this.accountService.getAccountEmail().subscribe(data:)
  }
  ngOnInit() {
    this.Email = this.route.snapshot.queryParamMap.get('Email');
    this.otp = Math.floor(100000 + Math.random() * 900000).toString();
  }
  sendOtp() {
    //  this.authService.verifyOtp(this.Email, this.otp).subscribe(response => {
    //    this.message = response.message;
    //  }, error => {
    //    this.message = 'Invalid OTP!';
    //  });
    this.accountService.getAccountEmail(this.getBase64Encode(this.Email)).subscribe((accountdata): void => {
      this.account.itemState = 2;
      this.account.abgTechnologysAccountUId = accountdata.abgTechnologysAccountUId;
      this.account.name = accountdata.name;
      this.account.email = accountdata.email;
      this.account.password = accountdata.password;
      this.account.mobileNumber = accountdata.mobileNumber;
      this.account.createdByApp = accountdata.createdByApp;
      this.account.createdByUser = accountdata.createdByUser;
      this.account.createdOn = accountdata.createdOn;
      this.account.productUId = accountdata.productUId;
      this.account.clientRegistrationUId = accountdata.clientRegistrationUId;
      this.account.roleBasedLogedin = accountdata.roleBasedLogedin;
      this.account.loggedInUId = accountdata.loggedInUId;
      this.account.accessRoleUId = accountdata.accessRoleUId;
      this.account.abgTechnologysAccountId = accountdata.abgTechnologysAccountId;
      this.account.modifiedByApp = accountdata.clientRegistrationUId.toString();
      this.account.modifiedByUser = accountdata.abgTechnologysAccountUId.toString();
      this.account.otp = this.otp;
      //this.setAuditFields(this.account);
      this.accountService.mergeAccount(this.account, 'Edit').subscribe((data): void => {

        this.router.navigate(['/verifyotp'], { queryParams: { Email: this.Email } })

      })
    })

  }
}
