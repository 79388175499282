import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[numberValidator]'
})
export class NumberValidatorDirective {

  inputElement: ElementRef;

  constructor(el: ElementRef) {
    this.inputElement = el;
    if (this.inputElement && this.inputElement.nativeElement && this.inputElement.nativeElement.value) {
      this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.trim();
    }
  }

  @HostListener('blur') onBlur() {
    this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.trim();
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    const pattern = /[0-9\ ]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
