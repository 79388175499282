import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AttendanceDevice } from '../../model/attendancedevice.model';
import { CoreGymService } from '../../_services/core.gym.service.service';
import { Guid } from '../../_helpers/guid';

@Injectable({
  providedIn: 'root'
})

export class AttendanceDeviceService {

  constructor(
    private readonly http: HttpClient,
    private readonly coreGymService: CoreGymService
  ) {
  }

  mergeAttendanceDevice(attendancedevice: AttendanceDevice, action: string): Observable<Response> {
    let httpheader = new HttpHeaders();
    httpheader = httpheader.append('Content-Type', 'application/json');
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetAttendanceDeviceUrl'];
    if (action === 'Edit' || action === 'Delete') {
      url = url + '/' + attendancedevice.attendanceDeviceUId;
      return this.http.put(url, JSON.stringify(attendancedevice), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    } else {
      return this.http.post(url, JSON.stringify(attendancedevice), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    }

  }

  getAttendanceDevice(clientRegistrationUId?: string): Observable<Array<AttendanceDevice>> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetAttendanceDeviceFilterUrl'];
    url = url.replace('{clientRegistrationUId}', clientRegistrationUId ? clientRegistrationUId : Guid.empty);
    return this.http.get(url)
      .pipe(map((data: Array<AttendanceDevice>): Array<AttendanceDevice> => data, (_error: HttpErrorResponse) => { }));
  }

  deleteAttendanceDevice(attendanceDeviceUId: Guid): Observable<Array<AttendanceDevice>> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetAttendanceDeviceUrl'];
    url = url + '/' + attendanceDeviceUId;
    return this.http.delete(url)
      .pipe(map((data: Array<AttendanceDevice>): Array<AttendanceDevice> => data, (_error: HttpErrorResponse) => { }));

  }

  getAttendanceDeviceMaxId(registrationUId?: Guid): Observable<AttendanceDevice> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetAttendanceDeviceMaxIdUrl'];
    return this.http.get(url)
      .pipe(map((data: AttendanceDevice): AttendanceDevice => data, (_error: HttpErrorResponse) => { }));

  }
}
