import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  constructor(private router: Router,
    private toastr: ToastrService) {
    // Clear alert message on route change
    this.toastr.toastrConfig.closeButton = true;
    this.toastr.toastrConfig.timeOut = 5000;
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // Only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // Clear alert
          this.subject.next({});
        }
      }
    });
  }

  success(message: string, keepAfterNavigationChange = false) {
    //this.keepAfterNavigationChange = keepAfterNavigationChange;
    //this.subject.next({ type: 'success', text: message });
    this.toastr.success(message);
  }

  error(message: string, keepAfterNavigationChange = false) {
    //this.keepAfterNavigationChange = keepAfterNavigationChange;
    //this.subject.next({ type: 'error', text: message });
    this.toastr.error(message);
  }

  info(message: string, keepAfterNavigationChange = false) {
    //this.keepAfterNavigationChange = keepAfterNavigationChange;
    //this.subject.next({ type: 'error', text: message });
    this.toastr.info(message);
  }

  warning(message: string, keepAfterNavigationChange = false) {
    //this.keepAfterNavigationChange = keepAfterNavigationChange;
    //this.subject.next({ type: 'error', text: message });
    this.toastr.warning(message);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
