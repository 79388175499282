import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Product } from '../../model/product.model';
import { CoreGymService } from '../../_services/core.gym.service.service';import { Guid } from '../../_helpers/guid';
;


@Injectable({
  providedIn: 'root'
})

export class ProductService {

  constructor(
    private readonly http: HttpClient,
    private readonly coreGymService: CoreGymService
  ) {
  }

  mergeProduct(product: Product, action: string): Observable<Response> {
    let httpheader = new HttpHeaders();
    httpheader = httpheader.append('Content-Type', 'application/json');
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetProductUrl'];
    if (action === 'Edit' || action === 'Delete') {
      url = url + '/' + product.productUId;
      return this.http.put(url, JSON.stringify(product), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    } else {
      return this.http.post(url, JSON.stringify(product), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    }
  }

  getProduct(ProductUId?: string): Observable<Array<Product>> {
    let url: string = this.coreGymService.BaseUrl!;
    if (ProductUId) {
      url = url + this.coreGymService.serviceUrls!['GetProductUIdUrl'];
      url = url.replace('{id}', ProductUId ? ProductUId : Guid.empty);
      return this.http.get(url)
        .pipe(map((data: Array<Product>): Array<Product> => data, (_error: HttpErrorResponse) => { }));
    }
    else {
      url = url + this.coreGymService.serviceUrls!['GetProductUrl'];
      return this.http.get(url)
        .pipe(map((data: Array<Product>): Array<Product> => data, (_error: HttpErrorResponse) => { }));
    }

  }
  deleteProduct(productUId: Guid): Observable<Array<Product>> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetProductUrl'];
    url = url + '/' + productUId;
    return this.http.delete(url)
      .pipe(map((data: Array<Product>): Array<Product> => data, (_error: HttpErrorResponse) => { }));

  }
  getProductMaxId(productUId?: Guid): Observable<Product> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetProductMaxIdUrl'];
    return this.http.get(url)
      .pipe(map((data: Product): Product => data, (_error: HttpErrorResponse) => { }));
  }
}
