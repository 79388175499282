<!--<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
        </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-title text-center">
                          <img src="assets/custom/images/abg/abglogo512.png" alt="branding logo">
                          </div>                        
                        <div class="card-content">-->
                          <!--<form name="forgotpwdForm" #forgotpwdForm="ngForm" (ngSubmit)="SavenewPassword(forgotpwdForm)">
    <div class="form-group position-relative has-icon-R">
      <select type="text" name="vertificationMethod" required (change)="selectedMethodisenabled($event)" class="form-control" #vertificationMethod="ngModel"
              [ngClass]="{ 'is-invalid': forgotpwdForm.submitted && vertificationMethod.errors }"
              [(ngModel)]="forgotpwd.vertificationMethod">
        <option [ngValue]="undefined" [disabled]="true">Choose Vertification Method</option>
        <option value="Email">Email</option>
        <option value="MobileNumber">MobileNumber</option>
      </select>
    </div>
    <div class="form-group position-relative has-icon-R">
      <input type="text" name="mobileNumber" required numberValidator maxlength="10" [disabled]="!mobilenumbers" (change)="getEmailID($event)" [ngClass]="{ 'is-invalid': forgotpwdForm.submitted && mobileNumber.errors }" minlenth="10" #mobileNumber="ngModel" placeholder="Mobile Number" class="form-control" [(ngModel)]="forgotpwd.mobileNumber" />
    </div>
    <div class="form-group position-relative has-icon-R">
      <input type="text" name="email" class="form-control" required email [disabled]="!emails" (change)="getMobileNumber($event)" [ngClass]="{ 'is-invalid': forgotpwdForm.submitted && email.errors }" maxlength="35" #email="ngModel" pattern="^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$" whiteSpaceValidator placeholder="Enter EmailID" [(ngModel)]="forgotpwd.email" />
      <div *ngIf="forgotpwdForm.submitted && email.errors" class="invalid-feedback">
        <div *ngIf="email.errors['required']"></div>
      </div>
    </div>
    <div class="form-group position-relative has-icon-R">
      <input type="date" name="dateOfBirth" required data-date-format="DD MMMM YYYY" class="form-control" disabled [(ngModel)]="forgotpwd.dateOfBirth" />
    </div>
    <div class="form-group position-relative has-icon-R">
      <select type="text" name="securityQuestion" class="form-control" #securityQuestion="ngModel"
              [ngClass]="{ 'is-invalid': forgotpwdForm.submitted && securityQuestion.errors }"
              [(ngModel)]="forgotpwd.securityQuestion">
        <option [ngValue]="undefined" [disabled]="true">Choose Security Question</option>
        <option value="Your mother's maiden name?">Your mother's maiden name?</option>
        <option value="In what city were you born?">In what city were you born?</option>
        <option value="Your favorite pet's name?">Your favorite pet's name?</option>
      </select>
    </div>
    <div class="form-group position-relative has-icon-R">
      <input type="text" name="securityQuestionOther" maxlength="50" [ngClass]="{ 'is-invalid': forgotpwdForm.submitted && securityQuestionOther.errors }" #securityQuestionOther="ngModel" placeholder="Enter Security Question Other(If Any)" class="form-control" [(ngModel)]="forgotpwd.securityQuestionOther" />
    </div>
    <div class="form-group position-relative has-icon-R">
      <input type="text" name="securityAnswer" required maxlength="50" [ngClass]="{ 'is-invalid': forgotpwdForm.submitted && securityAnswer.errors }" #securityAnswer="ngModel" placeholder="Answer for your Security Question" class="form-control" [(ngModel)]="forgotpwd.securityAnswer" />
    </div>
    <div class="form-group position-relative has-icon-R">
      <input type="password" name="newPassword" required maxlength="20" minlength="10" [ngClass]="{ 'is-invalid': forgotpwdForm.submitted && newPassword.errors }" #newPassword="ngModel" placeholder="Enter New Password" class="form-control" [(ngModel)]="forgotpwd.newPassword" />
    </div>
    <div class="form-group position-relative has-icon-R">
      <input type="password" name="confirmNewPassword" required maxlength="20" minlength="10" (change)="CheckConfirmPassword($event)" [ngClass]="{ 'is-invalid': forgotpwdForm.submitted && confirmNewPassword.errors }" #confirmNewPassword="ngModel" placeholder="Enter Confirm Password" class="form-control" [(ngModel)]="forgotpwd.confirmNewPassword" />
    </div>
    <div style="text-align:center" class="form-group position-relative has-icon-R">
      <button type="button" [disabled]="!otpclick" class="btn btn-secondary btn-min-width mr-1 mb-1" (click)="GenerateOTP()">Generate OTP</button>
    </div>
    <div class="form-group position-relative has-icon-R">
      <input type="text" name="verificationCode" required disabled placeholder="Verification Code" class="form-control" [(ngModel)]="forgotpwd.verificationCode" />
    </div>
    <button type="submit" [disabled]="!forgotpwdForm.valid" class="btn btn-outline-info btn-block">
      Forgot Password
    </button>
    <br />-->
                          <!--<div class="form-group row">-->
                          <!--<div class="col-md-6 col-12">
    <a [routerLink]="['/login']" class="card-link"> Go to Login?</a>
  </div>-->
                          <!--</div>-->
                          <!--</form>-->
                          <!--<div class="card-body">
                            <form [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit()">
                              <div *ngIf="!otpSent" class="form-group">
                                <label for="email">Email address</label>
                                <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                  <div *ngIf="f.email.errors.required">Email is required</div>
                                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                </div>
                              </div>
                              <button type="submit" [disabled]="forgetPasswordForm.invalid" class="btn btn-primary">Send OTP</button>
                            </form>
                          </div>
                        </div>
                        </div>
                      </div>
                  </div>
              </section>
          </div>
      </div>
  </div>-->

<div class="container">
  <h2>Forgot Password</h2>
  <form [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="email">Email address</label>
      <input type="email" formControlName="email" class="form-control" id="email" placeholder="Enter email">
      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
        <div *ngIf="f.email.errors.required">Email is required</div>
        <div *ngIf="f.email.errors.email">Email must be valid</div>
      </div>
    </div>
    <button type="submit" class="btn btn-primary" [disabled]="otpSent">Send OTP</button>
    <div *ngIf="errorMessage" class="alert alert-danger mt-2">{{ errorMessage }}</div>
  </form>
</div>
