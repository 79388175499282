import { ViewModelBase } from "./view-model-base.model";


export class SharedComponent {

  constructor() {

  }

  setAuditFields(obj: ViewModelBase): void {
    if (obj.itemState == 1) {
      obj.createdByApp = JSON.parse(localStorage.getItem('currentUser')).clientRegistrationUId;;
      obj.createdByUser = JSON.parse(localStorage.getItem('currentUser')).abgTechnologysAccountUId;;
      obj.createdOn = new Date(this.getUTCDate());
      obj.modifiedByApp = JSON.parse(localStorage.getItem('currentUser')).clientRegistrationUId;;
      obj.modifiedByUser = JSON.parse(localStorage.getItem('currentUser')).abgTechnologysAccountUId;;
      obj.modifiedOn = new Date(this.getUTCDate());
    }
    if (obj.itemState == 2) {
      obj.modifiedByApp = JSON.parse(localStorage.getItem('currentUser')).clientRegistrationUId;;
      obj.modifiedByUser = JSON.parse(localStorage.getItem('currentUser')).abgTechnologysAccountUId;;
      obj.modifiedOn = new Date(this.getUTCDate());
    }
  }

  setCRMAuditFields(obj: ViewModelBase): void {
    if (obj.itemState == 1) {
      obj.createdByApp = "string";
      obj.createdByUser = "string";
      obj.createdOn = new Date(this.getUTCDate());
      obj.modifiedByApp = "string";
      obj.modifiedByUser ="string";
      obj.modifiedOn = new Date(this.getUTCDate());
    }
    if (obj.itemState == 2) {
      obj.modifiedByApp = "string";
      obj.modifiedByUser = "string";
      obj.modifiedOn = new Date(this.getUTCDate());
    }
  }



  getDatewitharry(d: Dateclass): string {// { year: number, month: number, day: number }
    let date: Date;
    date = new Date(d.year + '-' + d.month + '-' + d.day);
    const utcDate: string = date.toISOString();
    return utcDate.substring(0, utcDate.length - 1);
  }
  stringtodate: Dateclass;
  getDatewithstring(d: string|Date): Dateclass {// { year: number, month: number, day: number }
    let date: Date;
    date = new Date(d);
    this.stringtodate = new Dateclass();
    this.stringtodate.year = date.getFullYear();
    this.stringtodate.month = date.getMonth();
    this.stringtodate.day = date.getDay();
    return this.stringtodate;
  }
  getUTCDate(d?: Date): string {
    let date: Date;
    date = new Date();
    const utcDate: string = date.toISOString();
    return utcDate.substring(0, utcDate.length - 1);
  }

  getBase64Encode(encode: string) {
    return btoa(encode);
  }
  getBase64Decode(decode: string) {
    return atob(decode);
  }

  openCalender(event: any) {
    event.showPicker();
  }

}
export class Dateclass {
  day: Number;
  month: Number;
  year: Number;
}
