import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { CoreGymService } from './core.gym.service.service';

@Injectable({
  providedIn: 'root'
})
export class ResolverResolver implements Resolve<any> {
  constructor(private coreGymService: CoreGymService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<any>> {
    return this.coreGymService.getAPIUrl()
      .then(es => {
        //this.coreGymService.setMaster(es);
        return es;
      }, error => {
        if (error) {
          return null;
        }
      });
  }
}
