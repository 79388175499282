import { Component, OnInit } from '@angular/core';
import { SharedComponent } from '../../shared/shared.component';
import { Account } from '../model/account.model';
import { Guid } from '../_helpers/guid';
import { AccountService } from '../_services/domainservice/account.service';
import { NgxSpinnerService, Spinner } from "ngx-spinner";
import { AlertService } from '../_services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-recoverypassword',
  templateUrl: './recoverypassword.component.html',
  styleUrls: ['./recoverypassword.component.css']
})
export class RecoverypasswordComponent extends SharedComponent implements OnInit {
  public breadcrumb: any;
  cols!: any[];
  Accountlist: Array<Account>;
  newpassword: string;
  account: Account = new Account;
  Email: string;
  constructor(private accountService: AccountService,
    readonly alertService: AlertService,
    private spinner: NgxSpinnerService,
    private route: Router,
    private router: ActivatedRoute)
  {
    super();
  }
  ngOnInit() {
    this.Email = this.router.snapshot.queryParamMap.get('Email');

  }
  Password(e) {
    this.newpassword = e.target.value;
  }
  CheckConfirmPassword(e) {
    if (this.newpassword === e.target.value) {

    }
    else {
      this.alertService.warning('Pls Enter the Correct Password');
      e.target.value = "";
    }
  }
  ChangePassword()
  {
    this.accountService.getAccountEmail(this.getBase64Encode(this.Email)).subscribe((getaccountdata): void => {
      this.account.itemState = 2;
      this.account.abgTechnologysAccountUId = getaccountdata.abgTechnologysAccountUId;
      this.account.name = getaccountdata.name;
      this.account.email = getaccountdata.email;
      this.account.password = this.newpassword;
      this.account.mobileNumber = getaccountdata.mobileNumber;
      this.account.createdByApp = getaccountdata.createdByApp;
      this.account.createdByUser = getaccountdata.createdByUser;
      this.account.createdOn = getaccountdata.createdOn;
      this.account.productUId = getaccountdata.productUId;
      this.account.clientRegistrationUId = getaccountdata.clientRegistrationUId;
      this.account.roleBasedLogedin = getaccountdata.roleBasedLogedin;
      this.account.loggedInUId = getaccountdata.loggedInUId;
      this.account.accessRoleUId = getaccountdata.accessRoleUId;
      this.account.abgTechnologysAccountId = getaccountdata.abgTechnologysAccountId;
      this.account.modifiedByApp = getaccountdata.clientRegistrationUId.toString();
      this.account.modifiedByUser = getaccountdata.abgTechnologysAccountUId.toString();
      this.account.otp = getaccountdata.otp;
      //this.setAuditFields(this.account);
      this.accountService.mergeAccount(this.account, 'Edit').subscribe((data): void => {
        this.alertService.success('Password Reset successfully');
        this.route.navigate(['/login'])

      })
    })

  }
}
