import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ClientRegistration } from '../../model/client.model';
import { CoreGymService } from '../../_services/core.gym.service.service';
import { Guid } from '../../_helpers/guid';



@Injectable({
  providedIn: 'root'
})

export class ClientService {

  constructor(
    private readonly http: HttpClient,
    private readonly coreGymService: CoreGymService
  ) {
  }

  mergeClient(client: ClientRegistration, action: string): Observable<Response> {
    let httpheader = new HttpHeaders();
    httpheader = httpheader.append('Content-Type', 'application/json');
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetClientUrl'];
    if (action === 'Edit' || action === 'Delete') {
      url = url + '/' + client.clientRegistrationUId;
      return this.http.put(url, JSON.stringify(client), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    } else {
      return this.http.post(url, JSON.stringify(client), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    }
  }
  getClient(clientRegistrationUId?: string): Observable<Array<ClientRegistration>> {
    let url: string = this.coreGymService.BaseUrl!;
    if (clientRegistrationUId) {
      url = url + this.coreGymService.serviceUrls!['GetClientUIdUrl'];
      url = url.replace('{id}', clientRegistrationUId ? clientRegistrationUId : Guid.empty);
      return this.http.get(url)
        .pipe(map((data: Array<ClientRegistration>): Array<ClientRegistration> => data, (_error: HttpErrorResponse) => { }));
    }
    else {
      url = url + this.coreGymService.serviceUrls!['GetClientUrl'];
      return this.http.get(url)
        .pipe(map((data: Array<ClientRegistration>): Array<ClientRegistration> => data, (_error: HttpErrorResponse) => { }));
    }

  }
  deleteClient(clientRegistrationUId: Guid): Observable<Array<ClientRegistration>> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetClientUrl'];
    url = url + '/' + clientRegistrationUId;
    return this.http.delete(url)
      .pipe(map((data: Array<ClientRegistration>): Array<ClientRegistration> => data, (_error: HttpErrorResponse) => { }));

  }
  getClientMaxId(clientRegistrationUId?: Guid): Observable<ClientRegistration> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetClientRegistrationMaxIdUrl'];
    return this.http.get(url)
      .pipe(map((data: ClientRegistration): ClientRegistration => data, (_error: HttpErrorResponse) => { }));

  }

  // upload() {
  //  const filedata = new FormData();
  //  filedata.append('file', this.SelectedFile, this.SelectedFile.name);
  //  this.http.post('https://localhost:7027/api/ClientRegistrations/upload', filedata)
  //    .subscribe(res => {
  //      console.log(res);
  //    })
  //}


  commonUpload(baseDirectory: string, relativePath: string, filedata: FormData): any {
    let httpheader = new HttpHeaders();
    httpheader = httpheader.append('Content-Type', 'application/json');
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['commonupload'];
    url = url.replace('{baseDirectory}', baseDirectory ? baseDirectory : Guid.empty);
    url = url.replace('{relativePath}', relativePath ? relativePath : Guid.empty);
    return this.http.post(url, filedata)
      .pipe(map((data: Response) => data,
        (error: HttpErrorResponse): void => {
          console.log(error);
        }
      ));
    //return this.http.post(url, filedata, { 'headers': httpheader })
    //  .pipe(map((data: Response) => data,
    //    (error: HttpErrorResponse): void => {
    //      console.log(error);
    //    }
    //  ));
  }
}
