import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[whiteSpaceValidator]'
})
export class WhiteSpaceValidatorDirective {

  inputElement: ElementRef;

  constructor(el: ElementRef) {
    this.inputElement = el;
    if (this.inputElement && this.inputElement.nativeElement && this.inputElement.nativeElement.value) {
      this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.trim();
    }
  }

  @HostListener('blur') onBlur() {
    this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.trim();
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    const e = <KeyboardEvent>event;
    let k;
    k = event.keyCode;
    const val = this.inputElement.nativeElement.value;
    if (k === 32) {
      if (!(val.replace(/\s/g, ''))) {
        e.preventDefault();
      }
      this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.trimStart();
    }
  }
}
