import { ViewModelBase } from '../../shared/view-model-base.model';
import { Guid } from '../_helpers/guid';
export class ClientRegistration extends ViewModelBase {
  clientRegistrationUId: Guid;
  clientRegistrationId: number;
  companyName: string;
  logo: string;
  date: string | Date;
  confidentialBusinessPurposes: boolean;
  fullName: string;
  dateofBirth: string | Date;
  gender: string;
  companyCertificateNumber: string;
  aadharNumber: string;
  panNumber: string;
  gstNumber: string;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  phoneNumberHome: string;
  mobileNumber1: string;
  mobileNumber2: string;
  emailAddress: string;
  industry: string;
  jobTitleOrPosition: string;
  companyAddress: string;
  companyCity: string;
  companyZipCode: string;
  companyCountry: string;
  companyEmailAddress: string;
  phoneNumberOffice: string;
  phoneNumber1: string;
  phoneNumber2: string;
  permanentAddress: string;
  communicationAddress: string;
  website: string;
  descriptionOfTheProjectOrServiceYouRequireAssistance: string;
  onlineSearch: boolean;
  referralFromFriendOrFamilyMember: boolean;
  socialMedia: boolean;
  advertisement: boolean;
  other: boolean;
  email: string;
  phone: string;
  inPersonMeetings: string;
  informationProvidedTheBestOfMyKnowledge: boolean
  signature: string;
  registrationDate: string | Date;
  joiningDate: string | Date;
  thankYouForChoosingOurServices: string;
  productUId: Guid;
  renewalDaysCount: number;
  redeemPointValue: number;



  constructor() {
    super();
  }
}
