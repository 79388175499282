<nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-shadow"
     [ngClass]="selectedHeaderNavBarClass">
  <div class="navbar-wrapper">
    <div id="navbar-header" class="navbar-header" [ngClass]="selectedNavBarHeaderClass"
         (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mobile-menu d-lg-none mr-auto">
          <a class="nav-link nav-menu-main menu-toggle hidden-xs11">
            <i class="feather ft-menu font-large-1"></i>
          </a>
        </li>
        <li class="nav-item mr-auto">
          <a [routerLink]="['/dashboard/gym']" class="navbar-brand">
            <img class="brand-logo" alt="modern admin logo" [src]="_themeSettingsConfig.brand.logo.value">
            <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3>
          </a>
        </li>
        <li class="nav-item d-none d-md-block nav-toggle">
          <a [routerLink]="" class="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
            <i class="feather toggle-icon font-medium-3 white"
               [ngClass]="{'ft-toggle-left': _themeSettingsConfig.menu === 'collapse','ft-toggle-right': _themeSettingsConfig.menu === 'expand'}"></i>
          </a>
        </li>
        <li class="nav-item d-lg-none">
          <a class="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile">
            <i class="la la-ellipsis-v"></i>
          </a>
        </li>
      </ul>
    </div>

    <!-- Notification Dropdown -->
    <div class="navbar-container content" [hidden]="isMobile && !showNavbar">
      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">
          <li class="nav-item d-none d-md-block">
            <a [routerLink]="" class="nav-link nav-link-expand" (click)="toggleFullScreen()" *ngIf="maximize === 'on'">
              <i class="ficon feather ft-maximize"></i>
            </a>
          </li>
          <li class="nav-item nav-search">
            <a [routerLink]="" class="nav-link nav-link-search" (click)="clickSearch()" *ngIf="search === 'on'">
              <i class="ficon feather ft-search"></i>
            </a>
            <div class="search-input" [ngClass]="{'open': isHeaderSearchOpen}">
              <input class="input" type="text" placeholder="Explore Modern...">
            </div>
          </li>
        </ul>
        <ul class="nav navbar-nav float-right">
          <li class="dropdown-language nav-item" ngbDropdown *ngIf="internationalization === 'on'">
            <a class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag">
              <i class="flag-icon flag-icon-gb"></i><span class="selected-language"></span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownLangMenu">
              <a class="dropdown-item">
                <i class="flag-icon flag-icon-gb"></i> English
              </a>
              <a class="dropdown-item">
                <i class="flag-icon flag-icon-fr"></i> French
              </a>
              <a class="dropdown-item">
                <i class="flag-icon flag-icon-cn"></i> Chinese
              </a>
              <a class="dropdown-item">
                <i class="flag-icon flag-icon-de"></i> German
              </a>
            </div>
          </li>

          <!-- Notification Dropdown -->
          <li class="dropdown-notification nav-item" ngbDropdown>
            <a class="nav-link nav-link-label" ngbDropdownToggle *ngIf="email === 'on'">
              <i class="ficon feather ft-mail"></i>
            </a>
            <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>
              <li class="dropdown-menu-header">
                <h6 class="dropdown-header m-0"><span class="grey darken-2">Messages</span></h6>
                <span class="notification-tag badge badge-default badge-warning float-right m-0">4 New</span>
              </li>
              <li class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y">
                <a href="javascript:void(0)">
                  <div class="media">
                    <div class="media-left">
                      <span class="avatar avatar-sm avatar-online rounded-circle">
                        <img src="../../../assets/images/portrait/small/avatar-s-19.png" alt="avatar">
                        <i></i>
                      </span>
                    </div>
                    <div class="media-body">
                      <h6 class="media-heading">Margaret Govan</h6>
                      <p class="notification-text font-small-3 text-muted">I like your portfolio, let's start.</p>
                      <small><time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">Today</time></small>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0)">
                  <div class="media">
                    <div class="media-left">
                      <span class="avatar avatar-sm avatar-busy rounded-circle">
                        <img src="../../../assets/images/portrait/small/avatar-s-2.png" alt="avatar">
                        <i></i>
                      </span>
                    </div>
                    <div class="media-body">
                      <h6 class="media-heading">Bret Lezama</h6>
                      <p class="notification-text font-small-3 text-muted">I have seen your work, there is</p>
                      <small><time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">Tuesday</time></small>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0)">
                  <div class="media">
                    <div class="media-left">
                      <span class="avatar avatar-sm avatar-online rounded-circle">
                        <img src="../../../assets/images/portrait/small/avatar-s-3.png" alt="avatar">
                        <i></i>
                      </span>
                    </div>
                    <div class="media-body">
                      <h6 class="media-heading">Carie Berra</h6>
                      <p class="notification-text font-small-3 text-muted">Can we have a call this week?</p>
                      <small><time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">Friday</time></small>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0)">
                  <div class="media border_bottom">
                    <div class="media-left">
                      <span class="avatar avatar-sm avatar-away rounded-circle">
                        <img src="../../../assets/images/portrait/small/avatar-s-6.png" alt="avatar">
                        <i></i>
                      </span>
                    </div>
                    <div class="media-body">
                      <h6 class="media-heading">Eric Alsobrook</h6>
                      <p class="notification-text font-small-3 text-muted">We have a project party this Saturday.</p>
                      <small><time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">last month</time></small>
                    </div>
                  </div>
                </a>
              </li>
              <li class="dropdown-menu-footer">
                <a class="dropdown-item text-muted text-center" href="javascript:void(0)">Read all messages</a>
              </li>
            </ul>
          </li>

          <!-- User Dropdown -->
          <li class="dropdown-user nav-item" ngbDropdown>
            <a class="nav-link dropdown-user-link" ngbDropdownToggle>
              <span>{{currentUser.name}}</span>
              <span class="avatar avatar-online">
                <span *ngIf="currentUser.gender=='Female'; else elsegender">
                  <img src="../../../assets/images/portrait/small/femaleprofile.jpg" alt="avatar">
                </span>
                <ng-template #elsegender>
                  <img src="../../../assets/images/portrait/small/maleprofile.jpg" alt="avatar" />
                </ng-template>
                <i></i>
              </span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">
              <div *ngIf="productuid=='00000010-0000-0000-0000-000000000000'">
                <select (change)="GetClients($event)" class="form-control">
                  <option value="value">Select Product</option>
                  <option [value]="pro.productUId" *ngFor="let pro of ProductDetailsList" [selected]="pro.productUId===selectedProductUId">
                    {{pro.productName}}
                  </option>
                </select>
                <select class="form-control" (change)='onOptionsSelected($event)'>
                  <option value="value">Select Client</option>
                  <option [value]="cli.clientRegistrationUId" *ngFor="let cli of Clients" [selected]="cli.clientRegistrationUId===selectedClientUId">
                    {{cli.companyName}}
                  </option>
                </select>
              </div>
              <a class="dropdown-item" [routerLink]="['/user/user-profile']">
                <i class="feather ft-user"></i> Edit Profile
              </a>
              <a class="dropdown-item" (click)="ResetPasswordPage()">
                <i class="la la-key"></i> Reset Password
              </a>
              <a class="dropdown-item" [routerLink]="" (click)="logout()">
                <i class="feather ft-power"></i> Logout
              </a>
            </div>
          </li>

          <!-- Notification Bell Icon -->
          <li>
            <!--<div class="toolbar">-->
            <div class="header-icon">
              <notification-center-component [subscriberId]="subscriberId"
                                             [applicationIdentifier]="applicationIdentifier"
                                             [sessionLoaded]="sessionLoaded"
                                             [styles]="styles.bellButton.root">
                <!-- Ensure correct binding here -->

              </notification-center-component>
            </div>
            </li>
          </ul>
        <div class="header-icon">
          <button class="bell-button" [ngStyle]="styles.bellButton.root" (click)="toggleNotifications()">
            <svg [ngStyle]="styles.bellButton.root.svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 2C10.34 2 9 3.34 9 5v2H7c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2v1h10v-1c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2h-2V5c0-1.66-1.34-3-3-3zm0 2c.552 0 1 .448 1 1v2H11V5c0-.552.448-1 1-1zm-6 6V9h12v1H6zm6 8c-1.66 0-3-1.34-3-3h6c0 1.66-1.34 3-3 3zm0-2h-.01H12z" />
            </svg>
            <div class="notification-dot" [ngStyle]="styles.bellButton.dot">
              <rect [ngStyle]="styles.bellButton.dot.rect"></rect>
            </div>
            <span class="button__badge">{{count}}</span>
          </button>
          <!-- Notification Dropdown -->
          <div *ngIf="showNotifications" class="notification-dropdown">

            <p *ngFor="let notification of Notificationlist">{{ notification.message }}</p>
          </div>
        </div>
            <!--</div>-->
          <!--</li>

        </ul>-->
      </div>
    </div>
  </div>
</nav>
