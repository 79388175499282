<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row ">
    </div>
    <div class="content-body">
      <section class="row flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 px-2 py-2 m-0">
              <div class="card-header border-0">
                <div class="card-title text-center">
                  <!--<img src="assets/custom/images/abg/abglogo512.png" alt="branding logo">-->
                  <label style="font-size:25px;color:deepskyblue">Reset Password</label>
                </div>
                <!--<h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                  <span>
                    We will send you
                    a link to reset password.
                  </span>
                </h6>-->
              </div>
              <div class="card-content">
                <div class="card-body">
                  <form class="form-horizontal">
                    <!--<fieldset class="form-group position-relative has-icon-left">
                      <input type="password" class="form-control" id="oldpassword" placeholder="Your Old Password" required (change)="CheckOldPassword($event)">
                      <div class="form-control-position">
                        <i class="la la-key"></i>
                      </div>
                    </fieldset>-->
                    <fieldset class="form-group position-relative has-icon-left">
                      <input type="password" class="form-control" id="newpassword" (change)="Password($event)" placeholder="Enter New Password"
                             required>
                      <div class="form-control-position">
                        <i class="la la-key"></i>
                      </div>
                    </fieldset>
                    <fieldset class="form-group position-relative has-icon-left">
                      <input type="password" class="form-control" id="oldconfirmpassword" (change)="CheckConfirmPassword($event)" placeholder="Enter Confirmation Password"
                             required>
                      <div class="form-control-position">
                        <i class="la la-key"></i>
                      </div>
                    </fieldset>
                    <button type="submit" class="btn btn-outline-info btn-lg btn-block" (click)="ChangePassword()">
                      <i class="feather ft-unlock"></i>
                      Change Password
                    </button>
                    <br />
                    <!--<div class="form-group row">
                      <div class="col-md-6 col-12">
                        <a [routerLink]="['/gym/dashboard']" class="card-link">Go to Dashboard?</a>
                      </div>
                    </div>-->
                  </form>
                </div>
              </div>
              <!--<div class="card-footer border-0">
                <p class="float-sm-left text-center">-->
              <!--[routerLink]="['/authentication/loginSimple']"-->
              <!--<a class="card-link">Login</a>
              </p>-->
              <!--<p class="float-sm-right text-center">
                New to Modern ? <a [routerLink]="['/authentication/registerSimple']" class="card-link">Create Account</a>
              </p>-->
              <!--</div>-->
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>
</div>

