import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../_services/domainservice/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  forgetPasswordForm: FormGroup;
  submitted = false;
  otpSent: boolean = false;
  errorMessage: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get f() { return this.forgetPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.forgetPasswordForm.invalid) {
      return;
    }

    this.spinner.show();
    this.authService.sendOtp(this.forgetPasswordForm.value.email).subscribe(
      response => {
        this.spinner.hide();
        this.otpSent = true;
        this.toastr.success('OTP sent to your email');
        this.router.navigate(['/sendotp'], { queryParams: { email: this.forgetPasswordForm.value.email } });
      },
      error => {
        this.spinner.hide();
        this.errorMessage = 'Error sending OTP. Please try again.';
        this.toastr.error(this.errorMessage);
      }
    );
  }
}



















//import { Component, Inject, OnInit, ViewChild } from '@angular/core';
//import { Router } from '@angular/router';
//import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
//import { AuthService } from '../_services/auth.service';
//import { AlertService } from '../_services/alert.service';
//import { UserService } from '../_api/user/user.service';
//import { ToastrService } from 'ngx-toastr';
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';
//import 'firebase/compat/firestore';
//import { ForgotPassword } from '../model/forgotpassword.model';
//import { SharedComponent } from '../../shared/shared.component';
//import { AccountService } from '../_services/domainservice/account.service';
//import { Account } from '../model/account.model';
//import { RoleBasedLoggedin } from '../model/masterModel/RoleBasedLogedin.model';
//import { GymService } from '../_services/domainservice/gym.service';
//import { TraineeRegistration } from '../model/gymtraineeregistration.model';
//import { GYMManagerRegistrationService } from '../_services/domainservice/gymmanagerregistration.service';
//import { TraineeRegistrationService } from '../_services/domainservice/gymtraineeregistration.service';
//import { RowStatuses } from '../model/masterModel/RowStatuses.model';
//import { Guid } from '../_helpers/guid';
//import { ForgotPasswordService } from '../_services/domainservice/forgotpassword.service';
//import { HttpErrorResponse, HttpClient } from '@angular/common/http';
//import { NgxSpinnerService } from 'ngx-spinner';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { error } from 'console';

//@Component({
//  templateUrl: 'register.component.html',
//  styleUrls: ['./register.component.css']
//})
//export class RegisterComponent extends SharedComponent implements OnInit {
//  forgetPasswordForm: FormGroup;
//  submitted = false;
//  otpSent: boolean = false;
//  errorMessage: string = '';
//  AccountList: Array<Account>;
//  //email: string = '';
//  //@ViewChild('forgotpwdForm', { static: false }) forgotpwdForm!: NgForm;

//  //forgotpwd: ForgotPassword = new ForgotPassword;
//  //emails: boolean = false;
//  //mobilenumbers: boolean = false;
//  //Accountlist: Array<Account>;
//  //passwordid: any;
//  //account: Account = new Account;
//  //otp: string = '';
//  //VerificationMethod: string = '';
//  //otpclick: boolean = true;
//  //generateOTP!= Number;
//  //private http = Inject(HttpClient);

//  constructor(
//    private formBuilder: FormBuilder,
//    private router: Router,
//    private alertService: AlertService,
//    private authService: AuthService,
//    private userService: UserService,
//    private accountService: AccountService,
//    private customerService: GymService,
//    private trainerService: TraineeRegistrationService,
//    private managerService: GYMManagerRegistrationService,
//    private forgetpasswordService: ForgotPasswordService,
//    private spinner: NgxSpinnerService,
//    private modalService: NgbModal,
//    private http: HttpClient
//      ) {
//    super();
//  }

//  ngOnInit() {
//    this.forgetPasswordForm = this.formBuilder.group({
//      email: ['', [Validators.required, Validators.email]]
//    });

//    //this.forgotpwd.forgotPasswordUId = Guid.newGuid();
//    //this.idgen();
//  }
//  get f() { return this.forgetPasswordForm.controls; }


//  onSubmit() {
//    if (this.forgetPasswordForm.valid) {
//      this.accountService.getAccountEmail(this.getBase64Encode(this.forgetPasswordForm.value.email)).subscribe((accountdata): void => {
//        if (this.forgetPasswordForm.value.email === accountdata.email) {
//          this.router.navigate(['/sendotp'], { queryParams: { Email: this.forgetPasswordForm.value.email } })
//        }
//      });
//      //this.authService.sendOtp(this.forgetPasswordForm.value.email).subscribe(response => {
//      //  this.errorMessage = response.message;
//      //  this.router.navigate(['/sendotp', this.forgetPasswordForm.value.email]);

//      //}, error => {
//      //  this.errorMessage = 'Error sending OTP!';
//      //});
//    }
//      //this.http.post('http://localhost:4200/register', this.forgetPasswordForm.value.email)
//      //  .subscribe(
//      //    (response) => {
//      //      this.otpSent = true; // Show OTP input
//      //      this.router.navigate(['/sendotp', this.forgetPasswordForm.value.email]);
//      //    },
//      //    (error) => {
//      //      this.errorMessage = 'Error sending OTP. Please try again.';
//      //      console.error(error);
//      //    }
//      //  );
    
//      //this.authService.sendOtp(this.email).subscribe(
//      //  response => {
//      //    alert('OTP sent to your email');
//      //    this.router.navigate(['/verifyotp', this.email]);
//      //  },
//      //  error => {
//      //    alert('Error sending OTP');
//      //  }
//      //);
//  }
 

//    //this.submitted = true;
//    //this.accountService.getAccountEmail(this.getBase64Encode(this.forgetPasswordForm.value.email)).subscribe((accountdata): void => {
//    //  if (this.forgetPasswordForm.value.email === accountdata.email) {
//    //    this.accountService.forgotsendmailpassword(accountdata).subscribe((data): void => {
//    //      alert('reset link is sent to ur registered emailid')
//    //      this.router.navigate(['/login'])
        
//    //    });

//    //  }
//    //})
//    //this.accountService.forgotsendmailpassword().subscribe((data): void => {
//    //  /* for (var i = 0; i < data.length; i++) {*/
//    //  if (this.forgetPasswordForm.value.email === data.email) {
//    //    alert('Reset Link is sent to ur registered EmailID')
//    //    this.router.navigate(['/login'])
//    //  }
//    //  else {
//    //    alert('something went wrong')
//    //  }
//    //  /*}*/
//    //})
//    //this.authService.forgetPassword(this.email);
//    //this.email = '';

//    //  this.submitted = true;
//    //  //this.alertService.clear();

//    //  if (this.forgetPasswordForm.invalid) {
//    //    return;
//    //  }

//    //  this.spinner.show();
//    //  this.authService.forgetPassword(this.forgetPasswordForm.value.email)
//    //    .subscribe({
//    //      next: () => {
//    //        this.alertService.success('Password reset link sent to your email');
//    //        this.router.navigate(['/login']);
//    //      },
//    //      error: (error) => {
//    //        this.alertService.error(error);
//    //        this.spinner.hide();
//    //      },
//    //      complete: () => this.spinner.hide()
//    //    });
//    //}

//  }
//  //idgen() {
//  //  this.forgetpasswordService.getForgotPasswordMaxId().subscribe((data: ForgotPassword): void => {
//  //    this.passwordid = data;
//  //  }, (error: HttpErrorResponse): void => {
//  //    this.forgotpwd.forgotPasswordId = 1000;
//  //    console.log(error);
//  //  });
//  //}
//  //selectedMethodisenabled(e) {
//  //  if (e.target.value === 'Email') {
//  //    this.emails = true;
//  //    this.mobilenumbers = false;
//  //  }
//  //  else if (e.target.value === 'MobileNumber') {
//  //    this.mobilenumbers = true;
//  //    this.emails = false;
//  //  }
//  //}
//  //GenerateOTP() {
//  //  this.otpgenertor();
//  //  this.otpclick = false;
//  //  //const otpData = { otp: this.otp };
//  //  //this.http.post('http://localhost:4200/api/verify-otp', otpData).
//  //  //  subscribe({
//  //  //    next: (Response: any) => {
//  //  //      if (Response.success) {
//  //  //        this.VerificationMethod = 'OTP Verified Successfully';
//  //  //      }
//  //  //      else {
//  //  //        this.VerificationMethod = 'OTP Verification Failed';

//  //  //      }
//  //  //    },
//  //  //    error: (error) => {
//  //  //      this.VerificationMethod = 'The Error Occur while verifying OTP';
//  //  //      console.error('There was on error!', error);
//  //  //    },
//  //  //  });
//  //}
//  //otpgenertor()
//  //{
//  //  let no = Math.random();
//  //  this.forgotpwd.verificationCode = Math.round(no * 900000 + 100000).toString();
//  //}

//  //getMobileNumber(e)
//  //{
//  //  this.accountService.getAccount().subscribe((getaccountdata): void => {
//  //    this.Accountlist = getaccountdata;
//  //    for (var i = 0; i < getaccountdata.length; i++) {
//  //      if (getaccountdata[i].email === e.target.value) {
//  //        this.forgotpwd.mobileNumber = getaccountdata[i].mobileNumber;
//  //        if (getaccountdata[i].roleBasedLogedin === '00200000-0000-0000-0000-000000000000') {
//  //          this.customerService.getGymRegistrationUID(getaccountdata[i].loggedInUId.toString()).subscribe((getcustomerdata): void => {
//  //            this.forgotpwd.dateOfBirth = getcustomerdata.dateofBirth.toString().slice(0, 10);
//  //          })
//  //        }
//  //        if (getaccountdata[i].roleBasedLogedin === '00100000-0000-0000-0000-000000000000')
//  //        {
//  //          this.trainerService.getTraineeRegistrationUID(getaccountdata[i].loggedInUId.toString()).subscribe((gettrainerdata): void => {
//  //            this.forgotpwd.dateOfBirth = gettrainerdata.dateofBirth.toString().slice(0,10);
//  //          })
//  //        }
//  //        if (getaccountdata[i].roleBasedLogedin === '00500000-0000-0000-0000-000000000000') {
//  //          this.managerService.getGYMManagerRegistrationUID(getaccountdata[i].loggedInUId.toString()).subscribe((getmanagerdata): void => {
//  //            this.forgotpwd.dateOfBirth = getmanagerdata.dateofBirth.toString().slice(0, 10);
//  //          })
//  //        }
//  //      } 
//  //    }
//  //  })
//  //}
//  //CheckConfirmPassword(e)
//  //{
//  //  if (this.forgotpwd.newPassword === e.target.value) {

//  //  }
//  //  else
//  //  {
//  //    this.alertService.warning('Pls check the Passwords')
//  //    e.target.value = "";
//  //  }
//  //}
//  //getEmailID(e)
//  //{
//  //  this.accountService.getAccount().subscribe((getaccountdata): void => {
//  //    this.Accountlist = getaccountdata;
//  //    for (var i = 0; i < getaccountdata.length; i++) {
//  //      if (getaccountdata[i].mobileNumber === e.target.value) {
//  //        this.forgotpwd.email = getaccountdata[i].email;
//  //        if (getaccountdata[i].roleBasedLogedin === '00200000-0000-0000-0000-000000000000') {
//  //          this.customerService.getGymRegistrationUID(getaccountdata[i].loggedInUId.toString()).subscribe((getcustomerdata): void => {
//  //            this.forgotpwd.dateOfBirth = getcustomerdata.dateofBirth.toString().slice(0, 10);
//  //          })
//  //        }
//  //        if (getaccountdata[i].roleBasedLogedin === '00100000-0000-0000-0000-000000000000') {
//  //          this.trainerService.getTraineeRegistrationUID(getaccountdata[i].loggedInUId.toString()).subscribe((gettrainerdata): void => {
//  //            this.forgotpwd.dateOfBirth = gettrainerdata.dateofBirth.toString().slice(0, 10);
//  //          })
//  //        }
//  //        if (getaccountdata[i].roleBasedLogedin === '00500000-0000-0000-0000-000000000000') {
//  //          this.managerService.getGYMManagerRegistrationUID(getaccountdata[i].loggedInUId.toString()).subscribe((getmanagerdata): void => {
//  //            this.forgotpwd.dateOfBirth = getmanagerdata.dateofBirth.toString().slice(0, 10);
//  //          })
//  //        }
//  //      }
//  //    }
//  //  })
//  //}
//  //SavenewPassword(forgotpwdForm) {
//  //  if (forgotpwdForm && forgotpwdForm.valid) {
     
//  //    /*this.forgotpwd.itemState = 1;*/
//  //    this.forgotpwd.rowStatusUId = RowStatuses.Active;
//  //    this.forgotpwd.forgotPasswordId = this.passwordid;
//  //    this.setAuditFields(this.forgotpwd);
//  //    this.spinner.show();
//  //    this.forgetpasswordService.mergeForgotPassword(this.forgotpwd, 'Add').subscribe((): void => {
//  //      this.spinner.hide();
//  //      this.UpdateAccountPassword();
//  //      this.alertService.success('New Password Saved successfully');
//  //      this.router.navigate(['/login']); 
//  //      //forgotpwdForm.reset();
//  //    },
//  //      (error: HttpErrorResponse): void => {
//  //        console.log(error);
//  //        this.alertService.error(error.message);
//  //        this.spinner.hide();
//  //      });
//  //  }
//  //  else {
//  //    this.alertService.warning('Please Enter Valid Fields');
//  //  }
//  //}
//  //UpdateAccountPassword()
//  //{
//  //  this.accountService.getAccount().subscribe((getaccountdata): void => {
//  //    this.Accountlist = getaccountdata;
//  //    for (var i = 0; i < getaccountdata.length; i++) {
//  //      if (getaccountdata[i].email === this.forgotpwd.email) {
//  //        getaccountdata[i].itemState = 2;
//  //        getaccountdata[i].password = this.forgotpwd.newPassword;
//  //        this.accountService.mergeAccount(getaccountdata[i], 'Edit').subscribe((): void => {
//  //        },
//  //          (error: HttpErrorResponse): void => {
//  //            console.log(error);
//  //            this.alertService.error(error.message);
//  //            this.spinner.hide();
//  //          });
//  //      }
//  //    }
//  //  },
//  //    (error: HttpErrorResponse): void => {
//  //      console.log(error);
//  //      this.alertService.error(error.message);
//  //      this.spinner.hide();
//  //    });
//  //}

