import { Guid } from "../app/_helpers/guid";


export class ViewModelBase {
  createdByUser: string;
  createdByApp: string;
  createdOn: string | Date;
  modifiedByUser: string;
  modifiedByApp: string;
  modifiedOn: string | Date;
  itemState: number;
  rowStatusUId: Guid;
}
