import { HttpClient } from '@angular/common/http';
import { FactoryProvider, Inject, Injectable, InjectionToken } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CoreGymService {

  getServiceUrl: string = '../../assets/ServiceUrls.json';
  public serviceUrls: ServiceUrlModel | undefined;
  public BaseUrl: string = '';
  public AttendanceBaseUrl: string = '';
  public CrmBaseUrl: string = '';

  constructor(private readonly http: HttpClient, @Inject(WINDOW) private window: Window) { }

  resolve() {
    return this.initialize();
  }

  public initialize() {
    this.getAPIUrl();   
  }
  getAPIUrl() {
    return this.http.get<ServiceUrlModel>(this.getServiceUrl)
      .toPromise()
      .then((result: ServiceUrlModel | undefined) => {
        this.serviceUrls = result;
        let hostname = this.getHostname();
        //www.handsgymapp.abgtechnologys.com
        if (hostname === "localhost") {
          this.BaseUrl = this.serviceUrls!['LocalHostURL']
          this.AttendanceBaseUrl = this.serviceUrls!['LocalHostAttendenceURL']
          this.CrmBaseUrl = this.serviceUrls!['LocalHostCrmURL']
        } else {
          this.BaseUrl = this.serviceUrls!['handsgymserviceapi']
          this.AttendanceBaseUrl = this.serviceUrls!['abgtechnologysattendanceserviceapi']
        }
      });
  }

  getHostname(): string {
    return this.window.location.hostname;
  }

  public LoadMaster() {
    return Promise.resolve();
  }
  public setMaster() { }
}

interface ServiceUrlModel {
  [Key: string]: string;
}

export const WINDOW = new InjectionToken<Window>('window');

const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
};

export const WINDOW_PROVIDERS = [
  windowProvider
]
