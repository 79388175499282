import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Menu } from '../../model/menu.model';
import { CoreGymService } from '../../_services/core.gym.service.service';
import { Guid } from '../../_helpers/guid';




@Injectable({
  providedIn: 'root'
})

export class MenuService {

  lostOfMenu: Array<Menu> = [];

  private menuList = new BehaviorSubject(this.lostOfMenu);
  getMenus = this.menuList.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly coreGymService: CoreGymService
  ) {
  }

  setmenu(menu: Array<Menu>) {
    menu = menu.sort(function (a, b) {
      return a.displayOrder - b.displayOrder;
    });
    this.menuList.next(menu);
  }

  mergeMenu(menu: Menu, action: string): Observable<Response> {
    let httpheader = new HttpHeaders();
    httpheader = httpheader.append('Content-Type', 'application/json');
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetMenuUrl'];
    if (action === 'Edit' || action === 'Delete') {
      url = url + '/' + menu.menuUId;
      return this.http.put(url, JSON.stringify(menu), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    } else {
      return this.http.post(url, JSON.stringify(menu), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    }
  }

  getMenu(menuUId?: string): Observable<Array<Menu>> {
    let url: string = this.coreGymService.BaseUrl!;
    if (menuUId) {
      url = url + this.coreGymService.serviceUrls!['GetMenuUIdUrl'];
      url = url.replace('{id}', menuUId ? menuUId : Guid.empty);
      return this.http.get(url)
        .pipe(map((data: Array<Menu>): Array<Menu> => data, (_error: HttpErrorResponse) => { }));
    }
    else {
      url = url + this.coreGymService.serviceUrls!['GetMenuUrl'];
      return this.http.get(url)
        .pipe(map((data: Array<Menu>): Array<Menu> => data, (_error: HttpErrorResponse) => { }));
    }

  }

  getMenuProduct(ProductUId?: string, RoleBasedMenus?: string): Observable<Array<Menu>> {
    let url: string = this.coreGymService.BaseUrl!;
    if (ProductUId) {
      url = url + this.coreGymService.serviceUrls!['GetMenuFilterProduct'];
      url = url.replace('{ProductUId}', ProductUId ? ProductUId : Guid.empty);
      url = url.replace('{RoleBasedMenus}', RoleBasedMenus ? RoleBasedMenus : Guid.empty);
      return this.http.get(url)
        .pipe(map((data: Array<Menu>): Array<Menu> => data, (_error: HttpErrorResponse) => { }));
    }
    else {
      url = url + this.coreGymService.serviceUrls!['GetMenuUrl'];
      return this.http.get(url)
        .pipe(map((data: Array<Menu>): Array<Menu> => data, (_error: HttpErrorResponse) => { }));
    }

  }
  deleteMenu(menuUId: Guid): Observable<Array<Menu>> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetMenuUrl'];
    url = url + '/' + menuUId;
    return this.http.delete(url)
      .pipe(map((data: Array<Menu>): Array<Menu> => data, (_error: HttpErrorResponse) => { }));

  }
}
