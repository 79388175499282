export * from './register.component';

//const express = require('express');
//const router = express.Router();
//var nodemailer = require('nodemailer');
//const User = require('../model/account.model');

//router.post('/register', async (req, res) => {
//  const { email } = req.body;
//  const user = await User.findOne({ email });

//  if (!user) {
//    return res.status(400).send('User with given email does not exist.');
//  }

//  const resetToken = user.getResetPasswordToken();
//  await user.save();

//  const resetUrl = `http://${req.headers.host}/authentication/resetpassword/${resetToken}`;

//  const message = `You are receiving this email because you(or someone else) have requested a password reset.Please make a PUT request to: \n\n ${resetUrl}`;

//  try {
//    await sendEmail({
//      email: user.email,
//      subject: 'Password reset token',
//      message
//    });

//    res.status(200).send('Email sent');
//  } catch (err) {
//    user.resetPasswordToken = undefined;
//    user.resetPasswordExpire = undefined;
//    await user.save();

//    return res.status(500).send('Email could not be sent');
//  }
//});

//const sendEmail = async (options) => {
//  const transporter = nodemailer.createTransport({
//    service: process.env.EMAIL_SERVICE,
//    auth: {
//      user: process.env.EMAIL_USERNAME,
//      pass: process.env.EMAIL_PASSWORD
//    }
//  });

//  const mailOptions = {
//    from: process.env.EMAIL_FROM,
//    to: options.email,
//    subject: options.subject,
//    text: options.message
//  };

//  await transporter.sendMail(mailOptions);
//};

//module.exports = router;

