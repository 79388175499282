import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountService } from '../_services/domainservice/account.service'
import { SharedComponent } from '../../shared/shared.component';
import { Injectable } from '@angular/core';
import { ClientRegistration } from '../model/client.model';
import { ProductService } from '../_services/domainservice/product.service'
import { Account } from '../model/account.model';
import { MenuService } from '../_services/domainservice/menu.service';
import { Guid } from '../_helpers/guid';
import { AttendanceDeviceService } from '../_services/domainservice/attendancedevice.service';
import { AttendanceDevice } from '../model/attendancedevice.model';


@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends SharedComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;
  client: ClientRegistration = new ClientRegistration;
  clientRegistrationUId: string;
  Devicelist: Array<AttendanceDevice>;
  DeviceNamelist: string;
  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    private renderer: Renderer2,
    private productService: ProductService,
    readonly menuService: MenuService,
    private attendancedeviceService: AttendanceDeviceService

  ) {
    super();
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });

  }

  ngOnInit() {

    //email: ['john@pixinvent.com', Validators.required],
    //  password: ['password@123', Validators.required],
    //email: ['ganapathy@abgtechnologys.com', Validators.required],
    //password: ['ganbapathym110#', Validators.required],
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: false
    });
    // Remember Me
    if (localStorage.getItem('remember')) {
      this.renderer.removeClass(document.body, 'bg-full-screen-image');
      localStorage.removeItem('currentLayoutStyle');
      //this.router.navigate(['/dashboard/gym']);
    } else if (localStorage.getItem('currentUser')) {
      // Force logout on login if not remember me
      this.authService.doLogout();
      this.isPageLoaded = true;
    } else {
      this.isPageLoaded = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  getpasswordpage() {
    this.router.navigate(['/login/forgotpassword']);
  }

  tryLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    const value = {
      email: this.f.email.value,
      password: this.f.password.value
    };

    this.accountService.getAccountLogin(this.getBase64Encode(value.email), this.getBase64Encode(value.password)).subscribe((data): void => {
      // this.appservice.spinner.next(false);
      if (this.loginForm.controls['rememberMe'] && this.loginForm.controls['rememberMe'].value) {
        localStorage.setItem('remember', 'true');
      } else {
        localStorage.removeItem('remember');
      }
      this.setUserInStorage(data);
      this.setCurrentClientUId(data['clientRegistrationUId']);
      this.setCurrentRoleBasedlogedin(data['roleBasedLogedin'] ? data['roleBasedLogedin'] : Guid.empty);
      this.setCurrentloggedinUId(data['loggedInUId']);


      this.productService.getProduct(data['clientRegistration'].productUId).subscribe((product): void => {
        this.setProductInStorage(product);
        this.setCurrentProductUId(product['productUId'])
        //if (JSON.parse(localStorage.getItem('currentProduct')).productUId === '00000010-0000-0000-0000-000000000000') {

        //  this.menuService.getMenu().subscribe((menudata): void => {
        //    this.setMenuInStorage(menudata);
        //  },
        //    (error: HttpErrorResponse): void => {
        //      console.log(error);
        //      this.alertService.error(error.message);
        //    }
        //  );
        //} else {
        this.menuService.getMenuProduct(JSON.parse(localStorage.getItem('currentProduct')).productUId, data['roleBasedLogedin']).subscribe((menudata): void => {
          //this.setMenuInStorage(menudata);
          this.menuService.setmenu(menudata);
          this.redirectLogin(menudata);
          this.attendancedeviceService.getAttendanceDevice(data['clientRegistrationUId']).subscribe((getdevicedata): void => {
            this.Devicelist = getdevicedata;
            this.DeviceNamelist = this.Devicelist.filter(o => o.attendanceDeviceName).map(o => o.attendanceDeviceName).join('|').toString();
            this.setCurrentDeviceID(this.DeviceNamelist);
          },
            (error: HttpErrorResponse): void => {
              console.log(error);
              this.alertService.error(error.message);
            }
          );
          },
            (error: HttpErrorResponse): void => {
              console.log(error);
              this.alertService.error(error.message);
            }
          );
        //}
      },
        
        (error: HttpErrorResponse): void => {
          // console.log(error);
          // this.appservice.spinner.next(false);
          this.submitted = false;
          this.alertService.error(error.message);
        });

      
     
    },
      (error: HttpErrorResponse): void => {
        // console.log(error);
        // this.appservice.spinner.next(false);
        this.submitted = false;
        this.alertService.error(error.error.detail);
      }
    );


    //this.authService.doLogin(value).then(
    //  res => {
    //    if (this.loginForm.controls['rememberMe'] && this.loginForm.controls['rememberMe'].value) {
    //      localStorage.setItem('remember', 'true');
    //    } else {
    //      localStorage.removeItem('remember');
    //    }
    //    this.setUserInStorage(res);
    //    localStorage.removeItem('currentLayoutStyle');
    //    let returnUrl = '/dashboard/sales';
    //    if (this.returnUrl) {
    //      returnUrl = this.returnUrl;
    //    }
    //    this.router.navigate([returnUrl]);
    //  },
    //  err => {
    //    this.submitted = false;
    //    this.alertService.error(err.message);
    //  }
    //);
  }
  addCheckbox(event) {
    const toggle = document.getElementById('icheckbox');
    if (event.currentTarget.className === 'icheckbox_square-blue') {
      this.renderer.addClass(toggle, 'checked');
    } else if (event.currentTarget.className === 'icheckbox_square-blue checked') {
      this.renderer.removeClass(toggle, 'checked');
    }
  }
  setUserInStorage(user) {
    if (user.name) {
      localStorage.setItem('currentUser', JSON.stringify(user));
    } else {
      localStorage.setItem('currentUser', JSON.stringify(user));
    }
  }
  setProductInStorage(product) {
    if (product.productUId) {
      localStorage.setItem('currentProduct', JSON.stringify(product));
    }
    else {
      localStorage.setItem('currentProduct', JSON.stringify(product));
    }
  }

  setCurrentProductUId(CurrentProductUId) {
    localStorage.setItem('CurrentProductUId', CurrentProductUId);
  }
  setCurrentClientUId(CurrentClientUId) {
    localStorage.setItem('CurrentClientUId', CurrentClientUId);
  }
  setCurrentRoleBasedlogedin(CurrentRoleBasedlogedin) {
    localStorage.setItem('CurrentRoleBasedlogedin', CurrentRoleBasedlogedin);
  }
  setCurrentloggedinUId(CurrentloggedInUId) {
    localStorage.setItem('CurrentloggedInUId', CurrentloggedInUId);
  }
  setCurrentDeviceID(CurrentDeviceID) {
    localStorage.setItem('CurrentDeviceID', CurrentDeviceID);
  }

  //setMenuInStorage(Menus) {
  //  localStorage.setItem('currentMenus', JSON.stringify(Menus));
  //  this.redirectLogin(Menus);
  //}

  redirectLogin(UserDetails){
    localStorage.removeItem('currentLayoutStyle');
    UserDetails=UserDetails.sort(function (a, b) {
      return a.displayOrder - b.displayOrder;
    });
    let returnUrl = UserDetails[0].page;// '/dashboard/gym';
    if (this.returnUrl) {
      returnUrl = this.returnUrl;
    }
    //this.alertService.success("Welcome!");
    this.router.navigate([returnUrl]);
  }


}
