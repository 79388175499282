import { ViewModelBase } from '../../shared/view-model-base.model';
import { Guid } from '../_helpers/guid';
import { CustomerRegistration } from './gym.model';

export class Notification extends ViewModelBase {
  notificationUId: Guid;
  notificationId: number;
  name: string;
  clientRegistrationUId: Guid;
  productUId: Guid;
  roleBasedLogedin: Guid;
  gymRegistrationUId: Guid;
  message: string;
  isViewed: boolean;

  gymRegistration: CustomerRegistration;

}
