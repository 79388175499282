<ng-container *ngIf="layout === 'vertical'">
  <app-header-vertical></app-header-vertical>
</ng-container>

<ng-container *ngIf="layout === 'horizontal'" (window:resize)="onResize($event)">
  <app-header-horizontal></app-header-horizontal>
</ng-container>
<ngx-spinner type="ball-scale-multiple">
  <div class="ball-pulse-sync">
    <div></div>
    <div></div>
    <div></div>
  </div>
</ngx-spinner>
