<!--<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
    </div>
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-md-4 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
              <div class="card-header border-0 pb-0">
                <div class="card-title text-center">
                  <img src="assets/images/logo/logo-dark.png" alt="branding logo">
                </div>-->
<!--<h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
  <span>Easily Using</span>
</h6>-->
<!--</div>
-->
<!--<app-social-signin></app-social-signin>
<p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
  <span>OR Using Email</span>
</p>-->
<!--<div class="card-content">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">-->
<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
    </div>
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-md-4 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
              <div class="card-title text-center">
                <img src="assets/custom/images/abg/abglogo512.png" alt="branding logo">
              </div>
              <div class="card-content">
                <div class="card-body">
                  <!--<div class="card-header">Forget Password</div>-->
                  <div class="card-body">
                    <form [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit()">
                      <div class="form-group">
                        <label for="email">Email address</label>
                        <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email is required</div>
                          <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                      </div>
                      <button type="submit" class="btn btn-primary">Send Reset Link</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<!--</div>
      </div>
    </div>
  </div>
</div>-->
<!--</div>
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>
</div>-->
