import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhiteSpaceValidatorDirective } from '../app/_directives/whiteSpaceValidator';
import { NumberValidatorDirective } from '../app/_directives/numberValidator ';
import { AlphaNumericValidatorDirective } from '../app/_directives/alphaNumericValidator';


@NgModule({
  declarations: [WhiteSpaceValidatorDirective, NumberValidatorDirective, AlphaNumericValidatorDirective],
  imports: [
    CommonModule
  ],
  exports: [
    WhiteSpaceValidatorDirective, NumberValidatorDirective, AlphaNumericValidatorDirective
  ]
})
export class SharedModule { }
