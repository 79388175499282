import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CoreGymService } from '../../_services/core.gym.service.service';
import { Account } from '../../../app/model/account.model';
import { AccountLogin } from '../../model/AccountLogin.model';
import { Guid } from '../../_helpers/guid';

@Injectable({
  providedIn: 'root'
})  
export class AccountService {

  constructor(
    private readonly http: HttpClient,
    private readonly coreGymService: CoreGymService
  ) {
  }

  mergeAccount(account: Account, action: string): Observable<Response> {
    let httpheader = new HttpHeaders();
    httpheader = httpheader.append('Content-Type', 'application/json');
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetAccountUrl'];
    if (action === 'Edit' || action === 'Delete') {
      url = url + '/' + account.abgTechnologysAccountUId;
      return this.http.put(url, JSON.stringify(account), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    } else {
      return this.http.post(url, JSON.stringify(account), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    }
  }
 /* (accountUId?: string):*/
  getAccount(clientRegistrationUId?: string, productUId?: string): Observable<Array<Account>> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetAccountClientandProductUrl'];
    url = url.replace('{clientRegistrationUId}', clientRegistrationUId ? clientRegistrationUId : Guid.empty);
    url = url.replace('{productUId}', productUId ? productUId : Guid.empty);
    //url = url.replace('{RegistrationUId}', RegistrationUId ? RegistrationUId : Guid.empty);

    //url = url + '/' + accountUId;
    //url = url.replace('{RegistrationUId}', accountUId ? accountUId : Guid.empty);

    return this.http.get(url)
      .pipe(map((data: Array<Account>): Array<Account> => data, (_error: HttpErrorResponse) => { }));

  }

  getAccountUId(abgTechnologysAccountUId?: string): Observable<Array<Account>> {
    let url: string = this.coreGymService.BaseUrl!;
    if (abgTechnologysAccountUId) {
      url = url + this.coreGymService.serviceUrls!['GetAccountUIdUrl'];
      url = url.replace('{id}', abgTechnologysAccountUId ? abgTechnologysAccountUId : Guid.empty);
      return this.http.get(url)
        .pipe(map((data: Array<Account>): Array<Account> => data, (_error: HttpErrorResponse) => { }));
    }
    else {
      url = url + this.coreGymService.serviceUrls!['GetAccountUrl'];
      return this.http.get(url)
        .pipe(map((data: Array<Account>): Array<Account> => data, (_error: HttpErrorResponse) => { }));
    }

  }
  getAccountLogin(EmailId: string, Password: string ): Observable<Array<Account>> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['AccountLogin'];
    url = url.replace('{EmailId}', EmailId ? EmailId : "");
    url = url.replace('{Password}', Password ? Password : "");
    let httpheader = new HttpHeaders();
    httpheader = httpheader.append('Content-Type', 'application/json');
    return this.http.get(url)
      .pipe(map((data: Array<Account>): Array<Account> => data, (_error: HttpErrorResponse) => { }));
  }
  getAccountEmail(EmailId: string): Observable<Account> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['AccountEmail'];
    url = url.replace('{EmailId}', EmailId ? EmailId : "");
    let httpheader = new HttpHeaders();
    httpheader = httpheader.append('Content-Type', 'application/json');
    return this.http.get(url)
      .pipe(map((data:Account): Account => data, (_error: HttpErrorResponse) => { }));
  }

 
  deleteAccount(accountUId: Guid): Observable<Array<Account>> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetAccountUrl'];
    url = url + '/' + accountUId;
    return this.http.delete(url)
      .pipe(map((data: Array<Account>): Array<Account> => data, (_error: HttpErrorResponse) => { }));

  }
  getAccountId(accountUId?: Guid): Observable<Account> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetAccountMaxIdUrl'];
    return this.http.get(url)
      .pipe(map((data: Account): Account => data, (_error: HttpErrorResponse) => { }));

  }
  forgotsendmailpassword(account: Account) {
    //let httpheader = new HttpHeaders();
    //httpheader = httpheader.append('Content-Type', 'application/json');
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetforgotsendmailpasswordUrl'];
    url = url + '/' + account.abgTechnologysAccountUId;
    return this.http.put(url, JSON.stringify(account)/*, { 'headers': httpheader }*/)
      .pipe(map((data: Response) => data,
        (error: HttpErrorResponse): void => {
          console.log(error);
        }
      ));
  }
}
