import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedComponent } from '../../../shared/shared.component';
import { AlertService } from '../../_services/alert.service';
import { AuthService } from '../../_services/auth.service';
import { AccountService } from '../../_services/domainservice/account.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent extends SharedComponent implements OnInit {
  forgetPasswordForm: FormGroup;
  submitted = false;
  //email: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private accountService: AccountService
  ) {
    super();
  }
  ngOnInit() {
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get f() { return this.forgetPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.accountService.getAccountEmail(this.getBase64Encode(this.forgetPasswordForm.value.email)).subscribe((data): void => {
     /* for (var i = 0; i < data.length; i++) {*/
        if (this.forgetPasswordForm.value.email === data[0].email) {
          alert('Valid EmailID')
        }
        else {
          alert('something went wrong')
        }
      /*}*/
    })
    //this.authService.forgetPassword(this.email);
    //this.email = '';
   
    //  this.submitted = true;
    //  //this.alertService.clear();

    //  if (this.forgetPasswordForm.invalid) {
    //    return;
    //  }

    //  this.spinner.show();
    //  this.authService.forgetPassword(this.forgetPasswordForm.value.email)
    //    .subscribe({
    //      next: () => {
    //        this.alertService.success('Password reset link sent to your email');
    //        this.router.navigate(['/login']);
    //      },
    //      error: (error) => {
    //        this.alertService.error(error);
    //        this.spinner.hide();
    //      },
    //      complete: () => this.spinner.hide()
    //    });
    //}

  }
}
