
import { ViewModelBase } from '../../shared/view-model-base.model';
import { Guid } from '../_helpers/guid';
import { ClientRegistration } from './client.model';
import { Accessrole } from './accessrole.model';
import { Product } from './product.model';

export class Account extends ViewModelBase {
  abgTechnologysAccountUId: Guid;
  abgTechnologysAccountId: number;
  mobileNumber: string;
  email: string;
  name: string;
  password: string;
  clientRegistrationUId: Guid;
  accessRoleUId: Guid;
  loggedInUId: Guid;
  roleBasedLogedin: Guid;
  otp: String;
  productUId: Guid;
  clientRegistration: ClientRegistration;
  accessRole: Accessrole;
  product: Product;
   
  constructor() {
    super();
  }
}
