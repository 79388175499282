import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedComponent } from '../../shared/shared.component';
import { AlertService } from '../_services/alert.service';
import { AccountService } from '../_services/domainservice/account.service';

@Component({
  selector: 'app-verifyotp',
  templateUrl: './verifyotp.component.html',
  styleUrls: ['./verifyotp.component.css']
})
export class VerifyotpComponent extends SharedComponent implements OnInit {
  otpForm: FormGroup;
  submitted = false;
  otpSent: boolean = false;
  otpVerified: boolean = false;
  errorMessage: string = '';
  Email: string;
  OTP: string;
  constructor(private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService
  ) {
    super();
  }
  ngOnInit() {
    this.Email = this.route.snapshot.queryParamMap.get('Email');
    this.OTP = this.route.snapshot.queryParamMap.get('OTP');
    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    });
  }
  get f1() { return this.otpForm.controls; }

  verifyOtp() {

    if (this.otpForm.valid) {
      this.accountService.getAccountEmail(this.getBase64Encode(this.Email)).subscribe((accountdata): void => {
        if (this.otpForm.value.otp === accountdata.otp)
        {
          this.alertService.success('OTP Verified Successfully');
          this.router.navigate(['/recoverypassword'], { queryParams: { Email: this.Email } })
        }
      });
      //const data = {
      //  email: this.Email,
      //  otp: this.otpForm.value.otp
      //};
      //this.http.post('http://localhost:4200/verifyotp', data)
      //  .subscribe(
      //    (response) => {
      //      this.otpVerified = true; // Allow password reset
      //      this.router.navigate(['/authentication/resetpassword', this.Email]);

      //    },
      //    (error) => {
      //      this.errorMessage = 'Invalid OTP. Please try again.';
      //      console.error(error);
      //    }
      //  );

    }
  }
}
