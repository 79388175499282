import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[alphaNumericValidator]'
})
export class AlphaNumericValidatorDirective {

  inputElement: ElementRef;

  constructor(el: ElementRef) {
    this.inputElement = el;
    if (this.inputElement && this.inputElement.nativeElement && this.inputElement.nativeElement.value) {
      this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.trim();
    }
  }

  @HostListener('blur') onBlur() {
    this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.trim();
  }

  @HostListener('keypress', ['$e']) alphaNumberOnly(e) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }

    e.preventDefault();
    return false;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }
}
  

 
