<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
    </div>
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-md-4 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
              <div class="card-title text-center">
                <img src="assets/custom/images/abg/abglogo512.png" alt="branding logo">
              </div>
              
              <div class="card-content">
                <!--<form>-->
                  <label>OTP:</label>&nbsp;<label>{{otp}}</label>
                  <br />
                  <h2 style="font-size:16px">Pls Copy the Otp and verify it</h2>
                <br />
                  <button type="submit" class="btn btn-primary" (click)="sendOtp()">Send</button>
                <!--</form>-->
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
