import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { error } from 'console';
import { AccountService } from './domainservice/account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = 'http://localhost:4200'; 
  //private baseUrl = '${environment.baseUrl}/auth';

constructor(public afAuth: AngularFireAuth,
  private http: HttpClient,
  private route: Router,
  private accountService: AccountService)
{ }
  sendOtp(email: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/sendotp`, { email });
  }
  verifyOtp(email: string, otp: string): Observable<any> {
    return this.http.post(`${ this.apiUrl }/verifyotp`, { email, otp });
  }
  resetPassword(email: string, newPassword: string): Observable<any> {
    return this.http.post(`${ this.apiUrl }/resetpassword`, { email, newPassword });
  }

  //forgetPassword(email: string) {
  //  this.accountService.getAccountEmail(email).subscribe((data): void => {
  //    for (var i = 0; i < data.length; i++) {
  //      if (email === data[i].email)
  //      {
  //        alert('Valid EmailID')
  //      }
  //      else
  //      {
  //        alert('something went wrong')
  //      }
  //    }
  //  })
     
    
    //this.afAuth.sendPasswordResetEmail(email).then(() => {
    //  alert('')
    //}, error => {
    //  alert('something went wrong')
    //})

  //return this.http.post('${this.baseUrl}/forgotpassword',{email});
/*}*/
 

  // Facebook login
  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Github login
  doGitHubLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GithubAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Twitter login
  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Google login
  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Register
  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            resolve(res);
          },
          err => reject(err)
        );
    });
  }

  // Login
  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            resolve(res);
          },
          err => reject(err)
        );
    });
  }

  // Logout
  doLogout() {
    return new Promise<void>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('remember');
        this.afAuth.signOut();
        resolve();
      } else {
        localStorage.removeItem('currentUser');
        resolve();
      }
    });
  }
}
