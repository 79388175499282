import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Notification } from '../../model/notification.model';
import { CoreGymService } from '../../_services/core.gym.service.service';
import { Guid } from '../../_helpers/guid';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(
    private readonly http: HttpClient,
    private readonly coreGymService: CoreGymService
  ) {
  }

  mergeNotification(notification: Notification, action: string): Observable<Response> {
    let httpheader = new HttpHeaders();
    httpheader = httpheader.append('Content-Type', 'application/json');
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetNotificationUrl'];
    if (action === 'Edit' || action === 'Delete') {
      url = url + '/' + notification.notificationUId;
      return this.http.put(url, JSON.stringify(notification), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    } else {
      return this.http.post(url, JSON.stringify(notification), { 'headers': httpheader })
        .pipe(map((data: Response) => data,
          (error: HttpErrorResponse): void => {
            console.log(error);
          }
        ));
    }

  }

  getNotificationUId(notificationUId?: string): Observable<Notification> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetNotificationFilterUIdUrl'];
    url = url.replace('{notificationUId}', notificationUId ? notificationUId : Guid.empty);
    return this.http.get(url)
      .pipe(map((data: Notification): Notification => data, (_error: HttpErrorResponse) => { }));
  }

  getNotification(clientRegistrationUId?: string,roleBasedLoginUId?:string): Observable<Array<Notification>> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetNotificationFilterUrl'];
    url = url.replace('{clientRegistrationUId}', clientRegistrationUId ? clientRegistrationUId : Guid.empty);
    url = url.replace('{roleBasedLoginUId}', roleBasedLoginUId ? roleBasedLoginUId : Guid.empty);
    return this.http.get(url)
      .pipe(map((data: Array<Notification>): Array<Notification> => data, (_error: HttpErrorResponse) => { }));
  }

  deleteNotification(notificationUId: Guid): Observable<Array<Notification>> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetNotificationUrl'];
    url = url + '/' + notificationUId;
    return this.http.delete(url)
      .pipe(map((data: Array<Notification>): Array<Notification> => data, (_error: HttpErrorResponse) => { }));

  }

  getNotificationMaxId(registrationUId?: Guid): Observable<Notification> {
    let url: string = this.coreGymService.BaseUrl!;
    url = url + this.coreGymService.serviceUrls!['GetNotificationMaxIdUrl'];
    return this.http.get(url)
      .pipe(map((data: Notification): Notification => data, (_error: HttpErrorResponse) => { }));

  }
}
